import { promotionCalculationService } from './calculation-service';
import {
  AppliedPromotionToVariant,
  InvoicePromotions,
  LineItem,
  LineItemPromotion,
  LineItemQuantities,
  Promotion,
  PromotionDiscountType,
  PromotionGroup,
  PromotionItemType,
  PromotionType,
} from './types';

export class PromotionEngineService {
  /**
   *
   * @param lineItems all cart line items
   * @returns InvoicePromotions containing promotions for line items
   * and promotion groups
   */
  public applyPromotionsToLineItems(lineItems: LineItem[]): InvoicePromotions {
    const appliedPromotionToItem: AppliedPromotionToVariant = {};
    const lineItemPromotions: LineItemPromotion[] = [];
    const promotionGroups: PromotionGroup[] = [];
    const promotions: Promotion[] = [];
    /**
     * Sorting so that xLineItem with smaller price
     * is given priority for promo application
     */
    const sortedLineItems = this.sortByPromoAndPrice(lineItems);
    for (const lineItem of sortedLineItems) {
      const { promotion } = lineItem;
      if (!promotion || appliedPromotionToItem[lineItem.lineItemId]) {
        /**
         * Promotion already applied or
         * No promotion exists
         */
        continue;
      }
      promotions.push(promotion);
      if (promotion.type === PromotionType.Simple) {
        lineItemPromotions.push(this.createLineItemPromotion(lineItem));
        appliedPromotionToItem[lineItem.lineItemId] = true;
      } else if (promotion.type === PromotionType.Conditional) {
        lineItemPromotions.push(
          ...this.applyConditionalPromotion(
            lineItem,
            lineItems,
            appliedPromotionToItem,
            lineItemPromotions,
            promotionGroups,
          ),
        );
      }
    }
    return {
      lineItemPromotions,
      promotionGroups,
      promotions,
    };
  }

  /**
   *
   * @param xLineItem line item that confirms to x part of buy x get y promo
   * @param lineItems all line items in cart
   * @param appliedPromotionToItem map for variants on which promo is applied
   * @returns LineItemPromotion[] for all the line items on which promo was applied
   */
  private applyConditionalPromotion(
    xLineItem: LineItem,
    lineItems: LineItem[],
    appliedPromotionToItem: AppliedPromotionToVariant,
    existinglineItemPromotions: LineItemPromotion[],
    promotionGroups: PromotionGroup[],
  ): LineItemPromotion[] {
    const { promotion } = xLineItem;
    if (!promotion) {
      return [];
    }
    const qualifiedLineItems = this.getQualifiedLineItems(lineItems, promotion);
    const sortedLineItems = this.sortLineItemsByListPrice(qualifiedLineItems);
    const xExistInY = this.doesXExistInY(
      promotion,
      xLineItem.variantId,
      xLineItem.categoryIds,
    );
    const promotionXQuantity = xExistInY
      ? this.getApplicableXQuantityForPromotion(
          xLineItem.quantity,
          promotion.quantity,
          promotion.y?.quantity || 0,
        )
      : 0;
    /**
     * Quantity of Y (excluding X) applicable for promotion
     */
    let promotionYQuantity = this.getApplicableYQuantityForPromotion(
      xLineItem.quantity - promotionXQuantity,
      promotion.quantity,
      promotion.y?.quantity || 0,
    );
    const maxPossiblePromoYQty = this.getApplicableYQuantityForPromotion(
      xLineItem.quantity,
      promotion.quantity,
      promotion.y?.quantity || 0,
    );
    let yQuantityLeft = maxPossiblePromoYQty;
    const lineItemPromotions: LineItemPromotion[] = [];
    /**
     * Actual quantity of X on which promo is applied
     */
    let quantityOfXReservedForPromo = 0;
    sortedLineItems.forEach((lineItem) => {
      if (lineItem.lineItemId === xLineItem.lineItemId) {
        quantityOfXReservedForPromo += Math.min(
          yQuantityLeft,
          lineItem.quantity,
        );
      }
      yQuantityLeft -= Math.min(yQuantityLeft, lineItem.quantity);
    });
    let currentPromotionGroupId = promotionGroups.length;
    let groupYQuantity = 0;
    while (sortedLineItems.length) {
      const item = sortedLineItems.shift()!;
      const yisX = item.lineItemId === xLineItem.lineItemId;
      const quantityLeftForPromo = yisX
        ? quantityOfXReservedForPromo
        : promotionYQuantity;
      let quantityOfItem = item.quantity;
      if (quantityLeftForPromo <= 0) {
        continue;
      }
      if (yisX) {
        // Same variant applicable for promotion (X -> X)
        quantityOfItem = promotionXQuantity;
      }
      let applicableQuantity =
        quantityLeftForPromo - quantityOfItem < 0
          ? quantityLeftForPromo
          : quantityOfItem;
      if (
        !yisX &&
        !this.isConditionalMoreFeasible(item, promotion, applicableQuantity)
      ) {
        continue;
      }
      let timesPromoApplied = Math.ceil(
        applicableQuantity / promotion.y!.quantity,
      );
      if (applicableQuantity === 0) {
        continue;
      }
      const existingPromotion = existinglineItemPromotions.find(
        (promo) => promo.lineItem.lineItemId === item.lineItemId,
      );
      if (
        existingPromotion &&
        existingPromotion.promotionDetails.id === promotion.id
      ) {
        /**
         * Same promotion from different xLineItem
         */
        existingPromotion.promotionDetails.applicableQuantity +=
          applicableQuantity;
      } else if (appliedPromotionToItem[item.lineItemId]) {
        continue;
      } else {
        lineItemPromotions.push(
          this.createLineItemPromotion(item, applicableQuantity, promotion),
        );
      }
      promotionYQuantity -= applicableQuantity;
      /**
       * Subtract quantity of y that is now part of promo
       * to avoid it being considered as X for another promo
       */
      const actualLineItem = lineItems.find(
        (lItem) => lItem.lineItemId === item.lineItemId,
      )!;
      actualLineItem.quantity -= applicableQuantity;
      /**
       * Subtract quantity of x that is now part of promo
       * to avoid it being considered for y for another promo
       */
      xLineItem.quantity -= promotion.quantity * timesPromoApplied;
      if (!item.promotion || item.promotion?.type === PromotionType.Simple) {
        /**
         * Chained promo case
         */
        appliedPromotionToItem[item.lineItemId] = true;
      }
      /**
       * Create Promotion Groups
       */
      while (applicableQuantity > 0) {
        const unitYQuantity = Math.min(
          promotion.y?.quantity!,
          Math.ceil(applicableQuantity / promotion.y?.quantity!),
        );
        const quantityThisGroupCanHave = Math.min(
          xLineItem.promotion?.y?.quantity!,
          groupYQuantity,
        );
        let quantityAppliedToThisGroup = Math.min(
          unitYQuantity,
          quantityThisGroupCanHave,
        );
        groupYQuantity += quantityAppliedToThisGroup;
        let currentPromotionGroup = promotionGroups.find(
          (group) => group.id === currentPromotionGroupId,
        );
        if (!currentPromotionGroup || quantityThisGroupCanHave === 0) {
          groupYQuantity = unitYQuantity;
          quantityAppliedToThisGroup = unitYQuantity;
          currentPromotionGroup = {
            id: currentPromotionGroupId,
            promotionId: promotion.id,
            xLineItemQuantities: this.getXLineItemQuantities(xLineItem),
            yLineItemQuantities: this.getYLineItemQuantities(
              actualLineItem,
              quantityAppliedToThisGroup,
            ),
          };
          promotionGroups.push(currentPromotionGroup);
        } else {
          const yLineItemQuantity =
            !item.xLineItemQuantities?.length &&
            currentPromotionGroup.yLineItemQuantities.find(
              (yItem) => yItem.lineItemId === item.lineItemId,
            );
          if (yLineItemQuantity) {
            yLineItemQuantity.quantity += quantityAppliedToThisGroup;
          } else {
            currentPromotionGroup.yLineItemQuantities.push(
              ...this.getYLineItemQuantities(
                actualLineItem,
                quantityAppliedToThisGroup,
              ),
            );
          }
        }
        currentPromotionGroup.yLineItemQuantities =
          this.groupLineItemQuantities(
            currentPromotionGroup.yLineItemQuantities,
          );
        if (groupYQuantity === promotion.y?.quantity) {
          currentPromotionGroupId++;
        }
        applicableQuantity -= quantityAppliedToThisGroup;
        currentPromotionGroup.yLineItemQuantities;
      }
    }
    return lineItemPromotions;
  }

  private groupLineItemQuantities(
    quantities: LineItemQuantities[],
  ): LineItemQuantities[] {
    const groupedQuantities: LineItemQuantities[] = [];
    quantities.forEach((quantity) => {
      const existingQuantity = groupedQuantities.find(
        (q) => q.lineItemId === quantity.lineItemId,
      );
      if (existingQuantity) {
        existingQuantity.quantity += quantity.quantity;
      } else {
        groupedQuantities.push(quantity);
      }
    });
    return groupedQuantities;
  }

  private getYLineItemQuantities(
    lineItem: LineItem,
    applicableQuantity: number,
  ): LineItemQuantities[] {
    if (!lineItem.xLineItemQuantities?.length) {
      return [
        {
          lineItemId: lineItem.lineItemId,
          variantId: lineItem.variantId,
          quantity: applicableQuantity,
          price: lineItem.price,
        },
      ];
    }
    const quantities: LineItemQuantities[] = [];
    let totalLineItemQuantityUsedAsY = applicableQuantity;
    if (lineItem.xLineItemQuantities) {
      let sortedLineItems = lineItem.xLineItemQuantities.sort(
        (a, b) => b.price - a.price,
      );
      let sortedLineItemsIndex = 0;
      while (totalLineItemQuantityUsedAsY > 0) {
        let currentLineItem = sortedLineItems[sortedLineItemsIndex];
        if (currentLineItem.quantity === 0) {
          ++sortedLineItemsIndex;
          if (sortedLineItemsIndex >= sortedLineItems.length) {
            break;
          }
          currentLineItem = sortedLineItems[sortedLineItemsIndex];
        }
        const yQuantityUsed = Math.min(
          currentLineItem.quantity,
          totalLineItemQuantityUsedAsY,
        );
        if (yQuantityUsed <= 0) {
          continue;
        }
        quantities.push({
          lineItemId: currentLineItem.lineItemId,
          variantId: currentLineItem.variantId,
          quantity: yQuantityUsed,
          price: currentLineItem.price,
        });
        currentLineItem.quantity -= yQuantityUsed;
        totalLineItemQuantityUsedAsY -= yQuantityUsed;
      }
    }
    return quantities;
  }

  private getXLineItemQuantities(xLineItem: LineItem): LineItemQuantities[] {
    if (!xLineItem.xLineItemQuantities?.length) {
      return [
        {
          lineItemId: xLineItem.lineItemId,
          variantId: xLineItem.variantId,
          quantity: xLineItem.promotion?.quantity!,
          price: xLineItem.price,
        },
      ];
    }
    const quantities: LineItemQuantities[] = [];
    let unitPromotionXQuantity = xLineItem.promotion?.quantity || 0;
    let sortedLineItems = xLineItem.xLineItemQuantities.sort(
      (a, b) => b.price - a.price,
    );
    let sortedLineItemsIndex = 0;
    while (unitPromotionXQuantity > 0) {
      let currentLineItem = sortedLineItems[sortedLineItemsIndex];
      if (currentLineItem.quantity === 0) {
        ++sortedLineItemsIndex;
        if (sortedLineItemsIndex >= sortedLineItems.length) {
          break;
        }
        currentLineItem = sortedLineItems[sortedLineItemsIndex];
      }
      const xQuantityUsed = Math.min(
        currentLineItem.quantity,
        unitPromotionXQuantity,
      );
      if (xQuantityUsed <= 0) {
        continue;
      }
      quantities.push({
        lineItemId: currentLineItem.lineItemId,
        variantId: currentLineItem.variantId,
        quantity: xQuantityUsed,
        price: currentLineItem.price,
      });
      currentLineItem.quantity -= xQuantityUsed;
      unitPromotionXQuantity -= xQuantityUsed;
    }
    return quantities;
  }

  private doesXExistInY(
    conditionalPromotion: Promotion,
    xVariantId: number,
    xCategoryIds: number[],
  ): boolean {
    if (conditionalPromotion.y?.isAll) {
      return true;
    }
    let xExistInY = false;
    if (conditionalPromotion.y?.itemType === PromotionItemType.Product) {
      xExistInY = !!conditionalPromotion.y.itemIds?.find(
        (item) => item === xVariantId,
      );
    } else if (
      conditionalPromotion.y?.itemType === PromotionItemType.Category
    ) {
      xExistInY = !!conditionalPromotion.y.itemIds?.find((item) =>
        xCategoryIds.some((categoryId) => categoryId === item),
      );
    }
    return xExistInY;
  }

  /**
   *
   * @param lineItemXQuantity quantity of item x in cart
   * @param promotionXQuantity min quantity of x required for promotion to be applicable
   * @param promotionYQuantity max quantity of all ys that can be part of promotion
   * @returns max quantity of item x on which promotion can be applied
   */
  private getApplicableXQuantityForPromotion(
    lineItemXQuantity: number,
    promotionXQuantity: number,
    promotionYQuantity: number,
  ): number {
    // Theory of Promotion by Sofia and Wahaj
    const totalPromoQuantity = promotionCalculationService.add(
      promotionXQuantity,
      promotionYQuantity,
    );
    const nonPromoItem = lineItemXQuantity % totalPromoQuantity;
    const promotionsApplied = promotionCalculationService.divide(
      promotionCalculationService.subtract(lineItemXQuantity, nonPromoItem),
      totalPromoQuantity,
    );
    let applicableQuantityOfX = promotionCalculationService.multiply(
      promotionsApplied,
      promotionYQuantity,
    );
    if (nonPromoItem > promotionXQuantity) {
      applicableQuantityOfX = promotionCalculationService.add(
        applicableQuantityOfX,
        promotionCalculationService.subtract(nonPromoItem, promotionXQuantity),
      );
    }
    return applicableQuantityOfX;
  }

  /**
   *
   * @param lineItemQuantity quantity of item x in cart
   * @param promotionXQuantity min quantity of x required for promotion to be applicable
   * @param promotionYQuantity max quantity of all y's that can be part of promotion
   * @returns max quantity of all item ys on which promotion can be applied
   */
  private getApplicableYQuantityForPromotion(
    lineItemQuantity: number,
    promotionXQuantity: number,
    promotionYQuantity: number,
  ): number {
    if (lineItemQuantity < promotionXQuantity) {
      // Not enough X quantity
      return 0;
    }
    const numberOfPromotions = Math.floor(
      promotionCalculationService.divide(lineItemQuantity, promotionXQuantity),
    );

    return promotionCalculationService.multiply(
      promotionYQuantity,
      numberOfPromotions,
    );
  }

  private isConditionalMoreFeasible(
    lineItem: LineItem,
    conditionalPromotion: Promotion,
    applicableQuantity: number,
  ): boolean {
    const { promotion } = lineItem;
    if (!promotion || promotion.type === PromotionType.Conditional) {
      // No simple promotion on variant
      return true;
    }

    const discountBySimplePromo = this.discountOffered(
      lineItem.price,
      promotion.discountType,
      promotion.amount,
      lineItem.quantity,
    );
    const discountByConditionalPromo = this.discountOffered(
      lineItem.price,
      conditionalPromotion.discountType,
      conditionalPromotion.amount,
      applicableQuantity,
    );

    return discountByConditionalPromo > discountBySimplePromo;
  }

  public discountOffered(
    price: number,
    discountType: PromotionDiscountType,
    amount: number,
    applicableQuantity: number,
  ): number {
    if (discountType === PromotionDiscountType.Amount) {
      return promotionCalculationService.multiply(amount, applicableQuantity);
    }
    const discountPercentage = promotionCalculationService.divide(amount, 100);
    return promotionCalculationService.multiply(
      applicableQuantity,
      promotionCalculationService.multiply(price, discountPercentage),
    );
  }

  /**
   *
   * @param lineItems all line items in cart
   * @returns price wise sorted line items
   */
  private sortLineItemsByListPrice(lineItems: LineItem[]): LineItem[] {
    return lineItems.sort(
      (lineItemA, lineItemB) => lineItemA.price - lineItemB.price,
    );
  }

  /**
   *
   * @param lineItems all line items in cart
   * @returns lineItems sorted by items where item that was 
   * only in x comes first then price wise sorted items
   */
  private sortByPromoAndPrice(lineItems: LineItem[]): LineItem[] {
    return lineItems.sort((a, b) => {
      if (this.isOnlyX(a) && !this.isOnlyX(b)) {
        return -1;
      }
      if (!this.isOnlyX(a) && this.isOnlyX(b)) {
        return 1;
      }
      return b.price - a.price;
    });
  }

  /**
   *
   * @param lineItems all line items in cart
   * @param conditionalPromotion buy x get y promo
   * @returns all line items that can be part of this conditionalPromotion
   */
  private getQualifiedLineItems(
    lineItems: LineItem[],
    conditionalPromotion: Promotion,
  ): LineItem[] {
    if (conditionalPromotion.y?.isAll) {
      return [...lineItems];
    }
    if (conditionalPromotion.y?.itemType === PromotionItemType.Category) {
      return this.getQualifiedLineItemsByCategory(
        lineItems,
        conditionalPromotion,
      );
    } else if (conditionalPromotion.y?.itemType === PromotionItemType.Product) {
      return this.getQualifiedLineItemsByVariants(
        lineItems,
        conditionalPromotion,
      );
    }
    return [];
  }

  /**
   *
   * @param lineItems all line items in cart
   * @param conditionalPromotion buy x get y promo
   * @returns all line items that can be part of this conditionalPromotion that match category promotion
   */
  private getQualifiedLineItemsByCategory(
    lineItems: LineItem[],
    promotion: Promotion,
  ): LineItem[] {
    return lineItems.filter((lineItem) =>
      lineItem.categoryIds.some((categoryId) =>
        promotion.y?.itemIds?.includes(categoryId),
      ),
    );
  }

  /**
   *
   * @param lineItems all line items in cart
   * @param conditionalPromotion buy x get y promo
   * @returns all line items that can be part of this conditionalPromotion that match variant promotion
   */
  private getQualifiedLineItemsByVariants(
    lineItems: LineItem[],
    promotion: Promotion,
  ): LineItem[] {
    return lineItems.filter((lineItem) =>
      promotion.y?.itemIds?.includes(lineItem.variantId),
    );
  }

  /**
   *
   * @param lineItem line item for which to create promo
   * @param quantity quantity of line item on which the promotion will be applied
   * @param conditionalPromotion buy x get y promo
   * @returns LineItemPromotion for this lineItem
   */
  private createLineItemPromotion(
    lineItem: LineItem,
    quantity?: number,
    conditionalPromotion?: Promotion,
  ): LineItemPromotion {
    const promotion = conditionalPromotion || lineItem.promotion;
    if (!promotion) {
      throw new Error(`Promotion not found`);
    }
    return {
      lineItem,
      promotionDetails: {
        quantity: promotion.quantity,
        name: promotion.name,
        id: promotion.id,
        amount: promotion.amount,
        type: promotion.type || PromotionType.Simple,
        discountType: promotion.discountType,
        startDate: promotion.startDate,
        endDate: promotion.endDate,
        y: promotion.y && {
          quantity: promotion.y.quantity,
        },
        applicableQuantity:
          quantity !== undefined
            ? Math.min(quantity, lineItem.quantity)
            : lineItem.quantity,
      },
    };
  }

  getYPromotionQuantity(lineItemId: number, groups: PromotionGroup[]): number {
    let quantity = 0;
    groups.forEach((group) => {
      const lineItemQuantities = group.yLineItemQuantities.filter(
        (yliq) => yliq.lineItemId === lineItemId,
      );
      lineItemQuantities.forEach((liq) => (quantity += liq.quantity));
    });
    return quantity;
  }

  getXPromotionQuantity(lineItemId: number, groups: PromotionGroup[]): number {
    let quantity = 0;
    groups.forEach((group) => {
      const lineItemQuantities = group.xLineItemQuantities.filter(
        (xliq) => xliq.lineItemId === lineItemId,
      );
      lineItemQuantities.forEach((liq) => (quantity += liq.quantity));
    });
    return quantity;
  }

  getTotalPromotionQuantity(
    lineItemId: number,
    groups: PromotionGroup[],
  ): number {
    return (
      this.getXPromotionQuantity(lineItemId, groups) +
      this.getYPromotionQuantity(lineItemId, groups)
    );
  }

  private isOnlyX(lineItem: LineItem): boolean {
    return (
      lineItem.promotion?.itemType === PromotionItemType.Product &&
      !lineItem.promotion?.y?.itemIds?.find((id) => id === lineItem.variantId)
    );
  }
}

export const promotionEngineService = new PromotionEngineService();
