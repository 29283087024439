export const environment = {
  name: 'mavericks',
  enablePendo: false,
  production: true,
  enableDatadog: false,
  enableServiceWorker: true,
  enableGoogleAnalytics: false,
  enableFullStory: false,
  applicationUrl: 'https://useast.qa.rebel.mims.rewaatech.com',
  webSocketUrl: 'wss://d5ejjrj9yc.execute-api.us-east-2.amazonaws.com/esther/',
  fullViewClientId: '',
  sallaAppClientId: '5c647dcf1b830aca7fd0ee6097d951f7',
  zidAppClientId: '2092',
  bonatAppClientId: '',
  bonatRedirectUrl: '',
  userPoolId: 'us-east-2_C0ilb9hjn',
  clientId: '727dt77ak885jvck3uuptd5llv',
  bonatServiceToken: '',
  cognitoAuthUrl: '',
  s3InventoryBucket:
    'https://s3.amazonaws.com/qa-rebel-mims-service-any-item-image/inventory/products/',
  defaultImage: 'default.png',
  enableHttp: true,
  enableIntercom: false,
  tapPublicKey: 'pk_test_jMgIW1ilDhXYSNPQ6BKob8aV',
  s3Bucket: 'https://s3.ap-south-1.amazonaws.com/qa.rebel.mims.rewaatech.com/',
  offline_intervals: { product: 2160000, customer: 2160000 },
  offline_config: {
    invoice_limit: { android: 10, others: 15 },
  },
  enableUserGuiding: false,
  enableUserPilot: false,
  launchDarklyClientSideId: '634feb3c703b801130f32c84',
  mockLaunchDarkly: false,
  enableAutomationTestFF: false,
  automationTestFFAPIKey: '123',
  pluginUrl: 'http://localhost:5000',
  accountingApiPath: '/api/accounting',
  expenseApiPath: '/api/expense-service',
  customFieldApiPath: '/api/custom-field-service',
  firebaseConfig: {
    apiKey: 'AIzaSyDpqm3hlLPwJ5mZmyX_GuGfth1lRWB1fHo',
    authDomain: 'rewaa-test.firebaseapp.com',
    projectId: 'rewaa-test',
    storageBucket: 'rewaa-test.appspot.com',
    messagingSenderId: '181952186125',
    appId: '1:181952186125:web:d9530938a18589a82f9244',
    measurementId: 'G-6XEQD0RRJV',
  },
  intercomAppId: 'sg0ry903',
  enableFirestore: true,
  allowAliasedEmails: true,
  reportDataLimit: 10000,
  reportingApiUrl:
    'https://reporting.useast.qa.rebel.mims.rewaatech.com/transformer',
  appVersion: require('../../package.json').version,
  nearpayEnvironment: 'sandbox',
  launchDarklyBaseUrl: undefined,
};
